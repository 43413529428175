<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <input type="text" v-model="search_data.pr_number"  id="pr_number" name="pr_number" class="form-control form-control-sm" placeholder="Search by PR number"/>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered text-center" id="purchase_request_list">
            <thead>
            <tr>
              <th>#</th>
              <th>PR Number</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(purchase_request, i) in purchase_requests.data" :key="i">
              <td>{{ purchase_requests.from + i}}</td>
              <td>{{ purchase_request.pr_number }}</td>
              <td>{{ customDate(purchase_request.created_at) }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.purchase-request-detail-modal @click="$bvModal.show('purchase-request-detail-modal'), status(purchase_request.maxPurchaseRequestStatus, purchase_request)" class="btn btn-sm btn-info mr-2">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>
                <router-link :to="{ name: 'freePurchaseRequestUpdate', params: { purchase_request_id: purchase_request.id } }" class="btn btn-sm btn-primary mr-2" tag="a">
                  <i class="fe fe-edit"/> Add/Update Free Product
                </router-link>
                <router-link :to="{ name: 'freePurchaseRequestDetail', params: { purchase_request_id: purchase_request.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> Detail
                </router-link>
                <a-button class="btn btn-sm btn-success mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(purchase_request.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> Download PR</a-button>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="purchase_requests.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="float-right">
            <pagination class="mt-2" :data="purchase_requests" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :purchase_request="purchase_request"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/freeProduct/purchaseRequest/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      purchase_requests: {},
      purchase_request: {},
      loading: false,
      loader: false,
      btnLoading: false,
      mailLoader: false,
      pdfDownloadLoader: false,
      index: -1,
      flag: false,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        pr_number: '',
        date_range: '',
      },
    }
  },
  mounted() {},
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.flag = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/purchase-request/search', this.search_data)
        .then(response => {
          this.loading = false
          this.flag = true
          this.btnLoading = false
          this.purchase_requests = response.data.purchase_requests
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.post('api/purchase-request/search?page=' + page, this.search_data)
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.purchase_requests = response.data.purchase_requests
        })
    },
    pdfDownload(purchaseRequestId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/purchase-request/pdf-download/' + purchaseRequestId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'purchase_request.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    status(purchaseRequestStatus, purchaseRequest) {
      this.current = 0
      this.purchase_request = ''
      this.current = purchaseRequestStatus
      this.purchase_request = purchaseRequest
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
